/**
 * Class with params element
 * Social Share list component
 * Display social share list with hover
 */
/* Import section */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, EmailShareButton} from "react-share"
import Sharefacebook from "../images/social/sharefacebook.inline.svg"
import Sharefacebookhover from "../images/social/sharefacebookhover.inline.svg"
import Sharelinkedin from "../images/social/sharelinkedin.inline.svg"
import Sharelinkedinhover from "../images/social/sharelinkedinhover.inline.svg"
import Sharemail from "../images/social/sharemail.inline.svg"
import Sharemailhover from "../images/social/sharemailhover.inline.svg"
import Sharetwitter from "../images/social/sharetwitter.inline.svg"
import Sharetwitterhover from "../images/social/sharetwitterhover.inline.svg"
import "../styles/components/socialshare.scss"

/* Declaration function */
class Socialsharelist extends Component {

  constructor(props) {
    super(props)
    this.state = {
      hoverSocialMedia: false
    }
  }

  manageHover(e, boolStateHover){
    this.setState({
      hoverSocialMedia: boolStateHover
    })
  }

  render () {
    var typeShare = this.props.typeshare


    if(typeShare === "facebook") {
      return (
        <FacebookShareButton
          url={this.props.urlArticle}
          quote={this.props.leadArticle}
          onMouseEnter={() => this.manageHover(this, true)}
          onMouseLeave={() => this.manageHover(this, false)}
          role="presentation"
        >
          {this.state.hoverSocialMedia ? <Sharefacebookhover className="icon" /> : <Sharefacebook className="icon" />}
        </FacebookShareButton>
      )
    }else if(typeShare === "linkedin"){
      return (
        <LinkedinShareButton
          url={this.props.urlArticle}
          quote={this.props.leadArticle}
          onMouseEnter={() => this.manageHover(this, true)}
          onMouseLeave={() => this.manageHover(this, false)}
          role="presentation"
        >
          {this.state.hoverSocialMedia ? <Sharelinkedinhover className="icon" /> : <Sharelinkedin className="icon" />}
        </LinkedinShareButton>
      )
    }else if(typeShare === "twitter"){
      return (
        <TwitterShareButton
          url={this.props.urlArticle}
          quote={this.props.leadArticle}
          onMouseEnter={() => this.manageHover(this, true)}
          onMouseLeave={() => this.manageHover(this, false)}
          role="presentation"
        >
          {this.state.hoverSocialMedia ? <Sharetwitterhover className="icon" /> : <Sharetwitter className="icon" />}
        </TwitterShareButton>
      )
    }else if(typeShare === "mail") {
      return (
        <EmailShareButton
          url={this.props.urlArticle}
          quote={this.props.leadArticle}
          onMouseEnter={() => this.manageHover(this, true)}
          onMouseLeave={() => this.manageHover(this, false)}
          role="presentation"
        >
        {this.state.hoverSocialMedia ? <Sharemailhover className="icon" /> : <Sharemail className="icon" />}
      </EmailShareButton>
        )
    }
  }
}

/* Function propTypes declaration */
Socialsharelist.propTypes = {
  urlArticle: PropTypes.string,
  leadArticle: PropTypes.string,
}

/* Export function */
export default Socialsharelist
