/**
 * Const with params element
 * Social Share component
 * Display social share button
 */
/* Import section */
import React from "react"
import Socialsharelist from "./socialsharelist"
import "../styles/components/socialshare.scss"

/* Declaration function */
const Socialshare = ({ slugTypeArticle, urlArticle, leadArticle }) => {
  const urlLink=`https://portalconnect.net/${slugTypeArticle}/${urlArticle}`

  return (
    <div className="social-share">
      <Socialsharelist key="social-share-facebook" urlArticle={urlLink} leadArticle={leadArticle} typeshare="facebook" />
      <Socialsharelist key="social-share-twitter" urlArticle={urlLink} leadArticle={leadArticle} typeshare="twitter" />
      <Socialsharelist key="social-share-linkedin" urlArticle={urlLink} leadArticle={leadArticle} typeshare="linkedin" />
      <Socialsharelist key="social-share-mail" urlArticle={urlLink} leadArticle={leadArticle} typeshare="mail" />
    </div>
  )
}

/* Export function */
export default Socialshare
