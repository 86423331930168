/**
 * Blog post Template
 */
/* Import section */
import { Link, graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import React from "react"
import Ctabuttonwitharrow from "../components/ctabuttonwitharrow"
import Layout from "../components/layout"
import Relatedarticle from "../components/relatedarticle"
import Sectionctafooter from "../components/sectionctafooter"
import Seo from "../components/seo"
import Socialshare from "../components/socialshare"
import "../styles/templates/blogpost.scss"

/* Declaration function */
const PostTemplate = ({ data }) => {

  const dataPost = data.wpPost
  const dataRelatedPost = data.relatedPost

  //CTA Footer page FAQ
  var ctaFooterFAQ= data.options.acfPageOptions.repeaterCtaFooter.filter(function (ctaFooter) {
    return ctaFooter.typeCtaFooter === "faq"
  })

  return (
    <Layout>
      <Seo
        title={dataPost.seo.title}
        description={dataPost.seo.metaDesc}
        datastructured="article"
        articleId={dataPost.databaseId}
        articlePicture={dataPost.acfPostBlogNews.imageArticle.localFile.childImageSharp.gatsbyImageData}
      />
      <section className="section-wrap-article">
        <article>
          <header className="header-article">
            <div className="title-header-article">
              <h1>{dataPost.title}</h1>
              <div className="author-infos">
                {dataPost.author.node.databaseId === 3 ? (
                  <StaticImage className="picture-author" src="../images/authors/danielhagon.jpg" alt={`blog author ${dataPost.author.node.name}`} layout="fixed" width={60} height={60} quality={100} />
                ) : (
                  <StaticImage className="picture-author" src="../images/authors/jennifermorency.jpg" alt={`blog author ${dataPost.author.node.name}`}layout="fixed" width={60} height={60} quality={100} />
                )}
                <div className="author-and-date">
                  <p className="author-article">{dataPost.author.node.name}</p>
                  <p className="date-article">{dataPost.date}</p>
                </div>
              </div>
            </div>
            <div className="picture-header-article">
              <GatsbyImage image={dataPost.acfPostBlogNews.imageArticle.localFile.childImageSharp.gatsbyImageData} alt={dataPost.acfPostBlogNews.imageArticle.altText} />
              <div className="bckg-picture"></div>
            </div>
          </header>
          <div className="share-article">
            <Socialshare slugTypeArticle={dataPost.categories.nodes[0].slug} urlArticle={dataPost.slug} leadArticle={dataPost.seo.metaDesc} />
          </div>
          <div className="content-article">
            <div className="back-blog">
              <Ctabuttonwitharrow ctaLabel="Back to Blog" ctaLink="/blog" arrow="left" otherStyle="bluemarin-format" />
            </div>
            <div className="lead-content-article" dangerouslySetInnerHTML={{ __html:dataPost.acfPostBlogNews.leadArticle}} />
            <div className="main-content-article" dangerouslySetInnerHTML={{ __html:dataPost.content}} />
          </div>
        </article>
        <aside>
          {/* Section with loop on tag */}
          <section className="section-tags">
            {dataPost.tags != null && (
              <ul className="list-tag">
                {dataPost.tags.nodes.map(({ name, slug }) => (
                  <li key={slug}><Link to={`/tag/${slug}/`}>{name}</Link></li>
                ))}
              </ul>
            )}
          </section>
          {/* Section with loop on related articles */}
          <section className="section-related-article">
            {dataRelatedPost.edges.map(({ node }) => (
              <Relatedarticle
                key={node.databaseId}
                titleArticle={node.title}
                srcImgArticle={node.acfPostBlogNews.imageArticle.localFile.childImageSharp.gatsbyImageData}
                altImgArticle={node.acfPostBlogNews.imageArticle.altText}
                typeSlugArticle={node.categories.nodes[0].slug}
                slugArticle={node.slug} />
            ))}
          </section>
          {/* Section about the Author */}
          <section className="section-author">
            <div className="box-author">
              <div className="picture-author">
                {dataPost.author.node.databaseId === 3 ? (
                  <StaticImage className="picture-author" src="../images/authors/danielhagon.jpg" alt={`blog author ${dataPost.author.node.name}`} layout="fixed" width={100} height={100} quality={100} />
                ) : (
                  <StaticImage className="picture-author" src="../images/authors/jennifermorency.jpg" alt={`blog author ${dataPost.author.node.name}`}layout="fixed" width={100} height={100} quality={100} />
                )}
              </div>
              <div className="infos-author">
                <h2>{dataPost.author.node.name}</h2>
                <p>{dataPost.author.node.description}</p>
              </div>
            </div>
          </section>
        </aside>
      </section>
      {/* Section CtaFooter */}
      {ctaFooterFAQ.map(function (ctafooter, i) {
        return <Sectionctafooter key={i} typeCta="faq" titleCta={ctafooter.titleCtaFooter} labelCta={ctafooter.labelCtaFooter} classCta="bluegreynormal" />
      })}
    </Layout>
  )
}
/* Export template informations */
export default PostTemplate

//QUERY GRAPHSQL ON POST
export const query = graphql`
  query($id: Int!, $tagid: [Int]) {
    wpPost(databaseId: { eq: $id }) {
      databaseId
      title
      slug
      content
      categories {
        nodes {
          databaseId
          slug
          name
        }
      }
      date (
          formatString: "MMMM DD, YYYY"
      )
      acfPostBlogNews {
        leadArticle
        imageArticle{
          altText
          localFile {
            childImageSharp {
              gatsbyImageData (layout: CONSTRAINED, width: 750)
            }
          }
        }
      }
      seo {
        metaDesc
        title
      }
      author {
        node {
          databaseId
          name
          slug
          description
        }
      }
      tags {
        nodes {
          name
          slug
        }
      }
    }
    relatedPost: allWpPost(filter: {databaseId: {ne: $id}, tags: {nodes: {elemMatch: {databaseId: {in: $tagid}}}}}, sort: {fields: [date], order:DESC}, limit: 4) {
      edges {
        node {
          databaseId
          title
          slug
          date (
            formatString: "MMMM DD, YYYY"
          )
          categories {
            nodes {
              databaseId
              slug
              name
            }
          }
          acfPostBlogNews {
            leadArticle
            imageArticle{
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData (layout: CONSTRAINED, width: 250)
                }
              }
            }
          }
        }
      }
    }
    allWpTag {
      edges {
        node {
          name
          slug
        }
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterCtaFooter {
          typeCtaFooter
          titleCtaFooter
          labelCtaFooter
        }
      }
    }
  }
`
