/**
 * Const with params element
 * CTA Button with arrow SVG component
 * Display a link as a button with arrow
 */
/* Import section */
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { IconContext } from "react-icons"
import { FiChevronRight, FiChevronLeft } from "react-icons/fi"
import "../styles/components/ctabuttonwitharrow.scss"

/* Declaration function */
const Ctabuttonwitharrow = ({ ctaLabel, ctaLink, arrow, otherStyle }) => {
  const defaultStyle = "cta-button-svg"

  return (
    <Link className={`${defaultStyle} ${otherStyle} ${arrow}`} to={`${ctaLink}/`}>
      {arrow === "right" && <span className="with-arrow-right">{ctaLabel}</span>}
      <IconContext.Provider value={{ className: "icon-chevron" }}>
        {arrow === "right" ? <FiChevronRight /> : <FiChevronLeft />}
      </IconContext.Provider>
      {arrow === "left" && <span className="with-arrow-left">{ctaLabel}</span>}
    </Link>
  )
}

/* Function propTypes declaration */
Ctabuttonwitharrow.propTypes = {
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string,
  arrow: PropTypes.string,
  otherStyle: PropTypes.string
}

Ctabuttonwitharrow.defaultProps = {
  ctaLabel: "Click here",
  ctaLink: "/",
  arrow: "right",
  otherStyle: ""
}

/* Export function */
export default Ctabuttonwitharrow
