/**
 * Const with params element
 * Related article component
 * Display a summary of related article
 */
/* Import section */
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Ctabutton from "./ctabutton"
import "../styles/components/relatedarticle.scss"


/* Declaration function */
const Relatedarticle = ({ titleArticle, srcImgArticle, altImgArticle, typeSlugArticle, slugArticle }) => {
  return (
    <div className="related-article">
      <div className="box-article">
      <Link to={`/${typeSlugArticle}/${slugArticle}/`}><GatsbyImage image={srcImgArticle} alt={`picture ${altImgArticle}`} /></Link>
        <h3><Link to={`/${typeSlugArticle}/${slugArticle}/`}>{titleArticle}</Link></h3>
        <div className="box-readmore">
          <div className="button-readmore">
            <Ctabutton ctaLabel="Read More" ctaLink={`/${typeSlugArticle}/${slugArticle}`} otherStyle="bluemarin-format" />
          </div>
        </div>
      </div>
    </div>
  )
}

/* Export function */
export default Relatedarticle
